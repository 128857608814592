export enum ValidationErrors {
    None = "None",
    FileType = "Type",
    FileName = "FileName",
    FolderDepth = "FolderDepth",
    LabelName = "LabelName"
}

export function isValidFileType(type: string) {
    const allowedTypes = ['image/jpeg', 'image/bmp', 'image/png'];
    return allowedTypes.includes(type);
}

export function isValidSlugName(filename: string) {
    const name_parts = filename.split('.');
    return !(name_parts.length > 2 || !/^[-a-zA-Z0-9_]+$/.test(name_parts[0]));
}

export function isValidFolderStructure(filePath: string) {
    const rel_path = filePath.split("/");
    return !(rel_path.length > 3);
}

export function isValidLabelName(filePath: string) {
    const rel_path = filePath.split("/");
    const inferredLabel = rel_path[rel_path.length-2];
    return isValidSlugName(inferredLabel) && inferredLabel !== "unlabeled";
}
