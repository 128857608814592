import {
    Configuration,
    FilesApi,
    ClassificationLabelsApi,
} from "../api-client";
import {checkWebkitdirectoryCompatibility} from "./fileUpload/fileUpload_ui";

import Cookies from "js-cookie";
import {fileUpload_change, fileUpload_submit} from "./fileUpload/fileUpload_eventListeners";

export function initUpload() {

    const app_domain: string = JSON.parse(document.getElementById('app_domain')?.textContent || '""');

    const apiClient = new FilesApi(new Configuration({
        basePath: app_domain,
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken') || "",
        }
    }));

    const labelApiClient = new ClassificationLabelsApi(new Configuration({
        basePath: app_domain,
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken') || "",
        }
    }));

    const dataset_id: string = JSON.parse(document.getElementById('dataset_id')?.textContent || '""');

    const form: HTMLFormElement | null = document.getElementById('upload') as HTMLFormElement;

    if (form) {

        //modify file counter on Change and evaluate filetype
        form.addEventListener('change', function (event) {
            fileUpload_change(form, event);
        });

        // Upload routine
        form.addEventListener('submit', async function (event) {
            await fileUpload_submit(form, event, apiClient, labelApiClient, dataset_id);  //TODO: check if this await breaks something
        });

        // Remove 'label from directory' option if browser is incompatible
        if (!checkWebkitdirectoryCompatibility()){
            const upload_options = document.getElementById("upload_options_select") as HTMLSelectElement;
            let dis_opt_i: number | null = null;
            if (upload_options && upload_options.options.length > 0){
                for (let i=0; i<upload_options.options.length; i++) {
                    if (upload_options.options[i].value == 'label_from_directory')
                        dis_opt_i = i;
                }
                if (dis_opt_i !== null)
                    upload_options.remove(dis_opt_i);
            }
        }
    }
}
