import {
    ApiUploadFinishCreateRequest,
    ApiUploadLocalCreateRequest,
    ApiUploadStartCreateRequest,
    DirectLabelCreationOutput,
    FileDirectUploadFinishInput,
    FileDirectUploadStartOutput, FilesApi,
} from "../../api-client";
import Cookies from "js-cookie";
import {appendErrorToList, increaseProgress} from "./fileUpload_ui";

export interface UploadData {
    file: File
    labelId: string | null
    labelName: string | null
    uploadRequest: ApiUploadLocalCreateRequest
    startRequest: ApiUploadStartCreateRequest
}

export interface LabelDict {
    [key: string]: number
}

export async function uploadFile(uploadData: FileDirectUploadStartOutput, file: File) {
    const formData = new FormData();
    const uploadHeaders = new Headers();

    if (uploadData.fields) {
        // handle aws related fields
        for (const key in uploadData.fields) {
            formData.append(key, uploadData.fields[key]);
        }

    } else {
        // no aws upload -> django authentication
        uploadHeaders.append('X-CSRFToken', Cookies.get('csrftoken') || "");
    }

    let body: File | FormData;
    formData.append('file', file); // 'file' is the name of the field where the file will be uploaded
    if (uploadData.method === "PUT") {
        uploadHeaders.append('Content-Type', file.type);
        body = file;
    } else {
        body = formData;
    }
    return await fetch(uploadData.url, {
        method: uploadData.method,
        headers: uploadHeaders,
        body: body,
    });
}

export async function retryableOperation(operation: () => Promise<any>, operationName: string, maxRetries: number, retryDelay: number) { // eslint-disable-line @typescript-eslint/no-explicit-any
    let attempt = 0;
    while (attempt < maxRetries) {
        try {
            return await operation();
        } catch (e) {
            attempt++;
            console.error(`${operationName} failed (attempt ${attempt} of ${maxRetries})`, e);
            if (attempt < maxRetries) {
                await new Promise(resolve => setTimeout(resolve, retryDelay));
            } else {
                const max_attempts_msg = `${operationName} failed after ${maxRetries} attempts`;
                if ('response' in e) {
                    const error = await e.response.json();
                    if (e.response.status == 400 && error.constructor === Array && error.length > 0) {
                        throw new Error(error[0]);
                    }
                }
                throw new Error(max_attempts_msg);
            }
        }
    }
}

export async function doUpload(apiClient: FilesApi, fileData: UploadData, length: number): Promise<void | FileDirectUploadFinishInput> {
    const maxRetries = 3; // Set the maximum number of retry attempts
    const retryDelay = 2000; // Delay between retries in milliseconds

    let sub_progress = 0;
    try {
        // Start request
        const startResult = await retryableOperation(
            () => apiClient.apiUploadStartCreate(fileData.startRequest),
            "Start Request",
            maxRetries,
            retryDelay
        );
        increaseProgress(1 / 3, length, false);
        sub_progress += 1 / 3;

        const fileId: number = startResult.id as number;

        // Upload file
        const uploadResult = await retryableOperation(
            () => uploadFile(startResult, fileData.file),
            "File Upload",
            maxRetries,
            retryDelay
        );
        increaseProgress(1 / 3, length, false);
        sub_progress += 1 / 3;

        if (uploadResult && uploadResult.ok) {
            // Finish request
            const data: ApiUploadFinishCreateRequest = {
                "id": fileId.toString()
            };
            if(fileData.labelId !== null){
                data.labelId = fileData.labelId;
            }
            const finishResult = await retryableOperation(
                () => apiClient.apiUploadFinishCreate(data),
                "Finish Request",
                maxRetries,
                retryDelay
            );

            increaseProgress(1 / 3, length, true);
            sub_progress += 1 / 3;

            if (finishResult.id) {
                return uploadResult;  // unused
            }
        } else {
            const errorMessage = await uploadResult.text();
            throw new Error(errorMessage);
        }
    } catch (e) {
        increaseProgress(1 - sub_progress, length, true);
        const progressBarPlus = document.getElementById('progress-bar-plus');
        if (progressBarPlus) {
            progressBarPlus.classList.add("bg-warning");
        }
        appendErrorToList(`Error processing '${fileData.file.name}': ${e.message}` as string);
        console.error("Upload process failed", e);
    }
}

export function createInferredLabelDict(labelCreationOutput: DirectLabelCreationOutput[]): LabelDict{
    const label_dict : LabelDict = {};
    for(let i=0; i<labelCreationOutput.length; i++){
        const lb = labelCreationOutput[i];
        label_dict[lb["name"]] = lb["id"];
    }
    return label_dict;
}
