/* tslint:disable */
/* eslint-disable */
/**
 * AutoMl Application API
 * Documentation of API endpoints of AutoMl Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DirectLabelCreationOutput
 */
export interface DirectLabelCreationOutput {
    /**
     *
     * @type {number}
     * @memberof DirectLabelCreationOutput
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof DirectLabelCreationOutput
     */
    name: string;
    /**
     *
     * @type {boolean}
     * @memberof DirectLabelCreationOutput
     */
    created: boolean;
    /**
     *
     * @type {boolean}
     * @memberof DirectLabelCreationOutput
     */
    _exists: boolean | null;
}

/**
 * Check if a given object implements the DirectLabelCreationOutput interface.
 */
export function instanceOfDirectLabelCreationOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "_exists" in value;

    return isInstance;
}

export function DirectLabelCreationOutputFromJSON(json: any): DirectLabelCreationOutput {
    return DirectLabelCreationOutputFromJSONTyped(json, false);
}

export function DirectLabelCreationOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectLabelCreationOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': json['id'],
        'name': json['name'],
        'created': json['created'],
        '_exists': json['exists'],
    };
}

export function DirectLabelCreationOutputToJSON(value?: DirectLabelCreationOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'name': value.name,
        'created': value.created,
        'exists': value._exists,
    };
}
