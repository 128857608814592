import {
    isValidFileType,
    isValidFolderStructure,
    isValidLabelName,
    isValidSlugName,
    ValidationErrors
} from "./fileUpload_validators";
import {appendErrorToList, setAlert, unsetAlert} from "./fileUpload_ui";
import {createInferredLabelDict, doUpload, LabelDict, UploadData} from "./fileUpload_aux";
import {ClassificationLabelsApi, DirectLabelCreationOutput, FilesApi} from "../../api-client";


export function fileUpload_change(form: HTMLFormElement, event: Event){
    event.preventDefault();
    const formData = new FormData(form);
    const files: FormDataEntryValue[] = formData.getAll("file_field");
    let isValid: boolean = true;
    let validationError: ValidationErrors = ValidationErrors.None;

    for (let i = 0; i < files.length; i++) {
        const f: File = files[i] as File;
        const fileType: string = f.type;
        const fileName: string = f.name;
        const filePath: string = f.webkitRelativePath;
        if (!isValidFileType(fileType)) {
            isValid = false;
            validationError = ValidationErrors.FileType;
            break;
        }
        if (!isValidSlugName(fileName)) {
            isValid = false;
            validationError = ValidationErrors.FileName;
            break;
        }
        if (filePath && !isValidFolderStructure(filePath)){
            isValid = false;
            validationError = ValidationErrors.FolderDepth;
            break;
        }
        if (filePath && !isValidLabelName(filePath)){
            isValid = false;
            validationError = ValidationErrors.LabelName;
            break;
        }
    }

    const fileInput: HTMLInputElement | null = document.getElementById('id_file_field') as HTMLInputElement;
    if (fileInput.files !== null && fileInput.files.length > 0) {
        if (!isValid && validationError !== ValidationErrors.None) {
            fileInput.value = "";
            setAlert(fileInput, validationError);

        } else {
            unsetAlert(fileInput);
        }
    }

    const upload_option = formData.get("upload_options");
    if((event.target as HTMLInputElement)?.id == "upload_options_select"){
        fileInput.value = '';
    }
    switch (upload_option) {
        case "default":
            document.getElementById("label_selector")?.classList.add("d-none");
            document.getElementById("upload-label-info")?.classList.add("d-none");
            fileInput.removeAttribute("webkitdirectory");
            break;
        case "use_label":
            document.getElementById("label_selector")?.classList.remove("d-none");
            document.getElementById("upload-label-info")?.classList.add("d-none");
            fileInput.removeAttribute("webkitdirectory");
            break;
        case "label_from_directory":
            document.getElementById("label_selector")?.classList.add("d-none");
            document.getElementById("upload-label-info")?.classList.remove("d-none");
            fileInput.setAttribute("webkitdirectory", "");
            break;
    }

    const fileCounter = document.getElementById("file-counter");
    if (fileCounter) {
        fileCounter.innerText = files.length.toString();
    }
}

export async function fileUpload_submit(form: HTMLFormElement, event: Event, apiClient: FilesApi, labelApiClient: ClassificationLabelsApi, dataset_id: string) {
    event.preventDefault();

    const formData = new FormData(form);

    const files = formData.getAll("file_field");

    const upload_options = formData.get("upload_options");
    let labelId = null;
    if (upload_options == "use_label") {
        labelId = formData.get("upload_classification_label") as string;
    }

    let inferredLabel = null;


    // generate list of data necessary for each file upload
    const uploadData: UploadData[] = [];
    const inferredLabels: string[] = [];

    for (const file of files) {
        if (file instanceof File) {
            const data: UploadData = {
                //id unknown here, is known from start response later
                "file": file,
                "labelId": labelId,
                "labelName": null,
                "uploadRequest": {"fileId": "null", "file": file as Blob},
                "startRequest": {"origFilename": file.name as string, "dataset": +dataset_id as number}
            };
            if(upload_options=="label_from_directory"){
                const rel_path = file.webkitRelativePath.split("/");
                inferredLabel = rel_path[rel_path.length-2];
                data["labelName"] = inferredLabel;
                if(!inferredLabels.includes(inferredLabel)){
                    inferredLabels.push(inferredLabel);
                }
            }
            uploadData.push(data);

        }
    }
    // Hide form and show progress bar
    form.style.display = "none";
    const progressContainer: HTMLElement | null = document.getElementById('progress-container');
    progressContainer?.classList.remove("visually-hidden");

    const upload_counter = document.getElementById("upload-counter");
    if (upload_counter) {
        upload_counter.innerText = "0";
    }

    // send single file requests by mapping doUpload promise to uploadData and execute them by Promise.all in parallel
    const length: number = uploadData.length;

    const cancelButton: HTMLElement | null = document.getElementById('cancel');
    cancelButton?.classList.add("visually-hidden");
    const submitButton: HTMLElement | null = document.getElementById('upload_submit');
    submitButton?.classList.add("visually-hidden");
    const batchSize = 5;
    const created_inferred_labels: DirectLabelCreationOutput[]= [];
    let inferred_labels_dict: LabelDict = {};
    if(inferredLabels.length > 0){
        for (let i = 0; i < inferredLabels.length; i ++) {
            try {
                const labelResult = await labelApiClient.apiUploadAddLabelCreate({
                    "name": inferredLabels[i] as string,
                    "dataset": +dataset_id as number
                });
                created_inferred_labels.push(labelResult);
            } catch (e) {
                const error = await e.response.json();
                if (e.response.status == 400 && error.constructor === Array && error.length > 0) {
                    appendErrorToList(`Error processing label '${inferredLabels[i]}': ${error[0]}` as string);
                }
                console.error("Creation of new labels failed. ", e);
            }
        }
        inferred_labels_dict = createInferredLabelDict(created_inferred_labels);
    }

    for (let i = 0; i < length; i += batchSize) {
        const batch = uploadData.slice(i, i + batchSize);
        if(inferredLabels.length > 0) {
            batch.forEach(element => {
                if(element["labelName"] && element["labelName"] in inferred_labels_dict){
                    element["labelId"] = inferred_labels_dict[element["labelName"]] as unknown as string;
                }
            });
        }
        await Promise.all(
            batch.map((fileData) => doUpload(apiClient, fileData, length))
        );
        const errors_list_container = document.getElementById("upload-errors");
        if (errors_list_container && errors_list_container.classList.contains("d-none")) {
            const errors_ul = document.getElementById("upload-errors-list-ul");
            if (errors_ul && errors_ul.getElementsByTagName('li').length >= 1) {
                errors_list_container.classList.remove("d-none");
            }
        }
    }

    // show back button when upload finished
    const backButton: HTMLElement | null = document.getElementById('back');
    const loading: HTMLElement | null = document.getElementById('uploading-files');
    backButton?.classList.remove("visually-hidden");
    if (loading) {
        loading.textContent = "Finished";
        const progressBarPlus = document.getElementById('progress-bar-plus');
        if (progressBarPlus) {
            progressBarPlus.classList.remove("progress-bar-striped");
        }
    }
}
