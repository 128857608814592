import {ValidationErrors} from "./fileUpload_validators";

export function increaseProgress(increase: number, length: number, update_files_counter: boolean) {
    const upload_counter = document.getElementById("upload-counter");
    const progressBarPlus = document.getElementById('progress-bar-plus');
    const upload_file_counter = document.getElementById("upload-file-counter");

    if (upload_file_counter && upload_file_counter.innerText === ""){
        upload_file_counter.innerText = `(0/${length})`;
    }

    if (upload_counter) {
        let state = parseFloat(upload_counter.innerText);
        state = (state + increase);
        upload_counter.innerText = state.toString();

        const progress = (state / length) * 100;
        if (progressBarPlus) {
            progressBarPlus.style.width = `${progress}%`;
        }

        if (upload_file_counter && update_files_counter) {
            upload_file_counter.innerText = `(${Math.round(state)}/${length})`;
        }

        // function gets 3 times called per index
    }
}

export function setAlert(fileInput: HTMLInputElement, valError: ValidationErrors) {
    if (fileInput) {
        const span = document.createElement("span");
        span.classList.add("invalid-feedback");
        span.id = "error_1_id_file_field";
        switch (valError) {
            case ValidationErrors.FileType:
                span.innerText = "Invalid File type, only .jpg, .png and .bmp files are supported";
                break;
            case ValidationErrors.FileName:
                span.innerText = "Invalid Filename, before the File extension only alphanumeric characters, dashes and underscores are allowed";
                break;
            case ValidationErrors.FolderDepth:
                span.innerText = "The selected folder has more than two levels. Please make sure that your selection has the structure: Folder > Label(s) > Image file(s)";
                break;
            case ValidationErrors.LabelName:
                span.innerText = "The inferred label names are not valid. Please make sure that the folder names contain only alphanumeric characters, dashes and underscores, and are not named 'unlabeled'";
                break;
        }
        fileInput.classList.add("is-invalid");
        fileInput.parentElement?.appendChild(span);
    }
}

export function unsetAlert(fileInput: HTMLInputElement) {
    if (fileInput) {
        fileInput.classList.remove("is-invalid");
        const span = document.getElementById("error_1_id_file_field");
        if (span) {
            fileInput.parentElement?.removeChild(span);
        }
    }
}

export function checkWebkitdirectoryCompatibility(){
    return 'webkitdirectory' in document.createElement('input');
}

export function appendErrorToList(error_msg: string) {
    const errors_ul = document.getElementById("upload-errors-list-ul");
    const error_li = document.createElement("li");
    if (errors_ul) {
        error_li.appendChild(document.createTextNode(error_msg));
        errors_ul.appendChild(error_li);
    }
}
