/* tslint:disable */
/* eslint-disable */
/**
 * AutoMl Application API
 * Documentation of API endpoints of AutoMl Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DirectLabelCreationOutput,
} from '../models/index';
import {
    DirectLabelCreationOutputFromJSON,
    DirectLabelCreationOutputToJSON,
} from '../models/index';

export interface ApiUploadAddLabelCreateRequest {
    dataset: number;
    name?: string | null;
}

/**
 *
 */
export class ClassificationLabelsApi extends runtime.BaseAPI {

    /**
     * Create label for image files uploaded with label extraction from folder structure.  Args:     request:     *args:     **kwargs:  Returns:     Result of the label creation.
     */
    async apiUploadAddLabelCreateRaw(requestParameters: ApiUploadAddLabelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DirectLabelCreationOutput>> {
        if (requestParameters.dataset === null || requestParameters.dataset === undefined) {
            throw new runtime.RequiredError('dataset','Required parameter requestParameters.dataset was null or undefined when calling apiUploadAddLabelCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.dataset !== undefined) {
            formParams.append('dataset', requestParameters.dataset as any);
        }

        const response = await this.request({
            path: `/api/upload/add_label`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DirectLabelCreationOutputFromJSON(jsonValue));
    }

    /**
     * Create label for image files uploaded with label extraction from folder structure.  Args:     request:     *args:     **kwargs:  Returns:     Result of the label creation.
     */
    async apiUploadAddLabelCreate(requestParameters: ApiUploadAddLabelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DirectLabelCreationOutput> {
        const response = await this.apiUploadAddLabelCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
